import React from 'react';

const WideContainer = ({ children }) => {
  const style = {
    width: '100%',
    textAlign: 'center',
    marginBottom: '30px',
  };

  return <div style={style}>{children}</div>;
};

export default WideContainer;
