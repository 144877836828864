import React from 'react';
import PageIcon from '../../../consumer-web-app/src/components/PageIcon';
import Paragraph from '../../../consumer-web-app/src/components/Paragraph';

const Container = ({ children }) => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '30px',
    width: '100%',
    height: '200px',
  };

  return <div style={style}>{children}</div>;
};

const LoadingPage = () => (
  <Container>
    <PageIcon src="../../assets/cloud-sync.png"/>
    <Paragraph>Please wait...</Paragraph>
    <Paragraph>Do not reload the page, or close the browser</Paragraph>
  </Container>
);

export default LoadingPage;
