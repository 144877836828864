import React from 'react';
import { Colors, Fonts, getResultIcon } from '../util';
import Paragraph from './Paragraph';

/**
 * Determine if a Thng is completely missing IOTA data.
 *
 * @param {object} thng - The Thng to check.
 * @returns {boolean} True if the Thng is missing required customFields.
 */
const thngHasNoData = thng => (!thng.customFields 
  || !thng.customFields.iotaRoot 
  || !thng.customFields.iotaRoot.length);

/**
 * Get the verdict details string based on the app state.
 *
 * @param {Array} actionHistory - The Thng's action history, unpacked.
 * @param {boolean} isAuthentic - The authenticity state.
 * @return {string} The verdict details string.
 */
const getDetailString = (state) => {
  const { thng, actionHistory, isAuthentic } = state;
  if (thngHasNoData(thng)) {
    return 'No data found!';
  }

  if (isAuthentic === null) {
    return 'Loading from blockchain...';
  }

  return isAuthentic
    ? 'Your product\'s journey can be verified as authentic by comparing with an immutable blockchain. This means that this information is proven to have not been tampered with since being created by the manufacturers.'
    : 'Your product\'s journey could not be verified. This could mean that its history is missing or does not agree with the immutable blockchain record of events, indicating a problem with its authenticity.';
};

const ResultIcon = ({ src }) => {
  const style = { height: 'auto', width: '40px' };

  return <img style={style} src={src}/>;
};

const Verdict = ({ isAuthentic }) => {
  const style = {
    color: isAuthentic ? Colors.lightGreen : Colors.notAuthenticText,
    fontFamily: Fonts.title,
    fontSize: '1.5rem',
    marginLeft: '10px',
    textAlign: 'center',
  };

  return <div style={style}>{isAuthentic ? 'Authentic' : 'Not Authentic'}</div>;
};

const RowContainer = ({ children, isAuthentic }) => {
  const style = {
    alignItems: 'center',
    // backgroundColor: isAuthentic ? Colors.authenticBackground : Colors.notAuthenticBackground,
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: '230px',
    padding: '7px',
  };

  return <div style={style}>{children}</div>;
};

const Reason = ({ children }) => {
  const style = { fontFamily: Fonts.body, textAlign: 'center' };

  return <div style={style}>Reason: {children}</div>;
};

const AuthenticityResult = ({ state }) => {
  const { isAuthentic, notAuthenticReason } = state;

  return (
    <div>
      <RowContainer isAuthentic={isAuthentic}>
        <ResultIcon src={getResultIcon(isAuthentic)}/>
        <Verdict isAuthentic={isAuthentic}/>
      </RowContainer>
      {notAuthenticReason.length > 0 && <Reason>{notAuthenticReason}</Reason>}
      <Paragraph>{getDetailString(state)}</Paragraph>
    </div>
  );
};

export default AuthenticityResult;
