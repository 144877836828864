import React from 'react';
import { Colors, Fonts } from '../util';
import ActionHistoryStack from '../components/ActionHistoryStack';
import AuthenticityResult from '../components/AuthenticityResult';
import BlockchainBanner from '../components/BlockchainBanner';
import CustomFieldsTable from '../components/CustomFieldsTable';
import Fader from '../components/Fader';
import Paragraph from '../components/Paragraph';
import ProductImage from '../components/ProductImage';
import Subtitle from '../components/Subtitle';
import Title from '../components/Title';
import ToggleButton from '../components/ToggleButton';
import WideContainer from '../components/WideContainer';

const HorizontalSpacer = () => {
  const style = {
    height: '2px',
    minWidth: '100px',
    backgroundColor: Colors.lightGrey,
    margin: '30px auto',
    maxWidth: '300px',
  };

  return <div style={style}/>;
};

class ResultsPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      historyShown: false,
    };
  }

  render() {
    const { product, isAuthentic } = this.props.state;

    return (
      <Fader>
        <WideContainer>
          <BlockchainBanner/>

          <Title>{product.name}</Title>
          <Paragraph>{product.brand}</Paragraph>
          <ProductImage product={product}/>

          <Subtitle>Item Details</Subtitle>
          <CustomFieldsTable data={product.customFields}/>

           
          <Subtitle>Authenticity</Subtitle>
          {isAuthentic !== null && (
            <Fader>
              <AuthenticityResult state={this.props.state}/>
            </Fader>
          )}
          {isAuthentic === null && <Paragraph>Loading from blockchain...</Paragraph>}

          {isAuthentic === true && (
            <Fader>
              <ToggleButton enabledText="Hide Journey" disabledText="Show Journey" 
                onToggle={historyShown => this.setState({ historyShown })}/>
            </Fader>
          )}
          {this.state.historyShown === true && (
            <Fader>
              <Subtitle>Product Journey</Subtitle>
              <Paragraph>
                The timeline below shows the detailed journey of the product from factory to your 
                hands.
              </Paragraph>
              <ActionHistoryStack state={this.props.state}/>
            </Fader>
          )}
        </WideContainer>
      </Fader>
    );
  }

}

export default ResultsPage;
