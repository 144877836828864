import React from 'react';
import { Colors, Fonts } from '../util';

const Paragraph = ({ children, italics }) => {
  const style = {
    color: Colors.darkGrey,
    fontFamily: Fonts.body,
    fontSize: '1.1rem',
    fontStyle: italics ? 'italic' : 'initial',
    margin: '10px auto 10px',
    maxWidth: '90%',
    textAlign: 'center',
  };

  return <p style={style}>{children}</p>;
};

export default Paragraph;
