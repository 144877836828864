import React from 'react';
import { Colors, Fonts, formatCamelCase } from '../util';

/**
 * Some fields need special transformation.
 *
 * @param {object} state - The app state.
 * @param {string} key - The customFields key.
 * @param {string} value - The customFields value.
 * @returns {string} The transformed value, if required.
 */
const handleSpecial = (state, key, value) => {
  if (key === 'product') {
    return state.products.find(p => p.id === value).name;
  }

  return value;
};

const Label = ({ children }) => {
  const style = {
    color: Colors.darkGrey,
    fontFamily: Fonts.body,
    fontSize: '1.1rem',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingRight: '5px',
    maxWidth: '145px',
  };

  return <td style={style}>{children}</td>;
};

const Value = ({ children }) => {
  const style = {
    color: Colors.darkGrey,
    fontFamily: Fonts.body,
    fontSize: '1.1rem',
    margin: '5px 10px',
    textAlign: 'left',
  };
  
  return <td style={style}>{children}</td>;
};

const TableItem = (state, key, value) => (
  <tr key={key}>
    <Label>{formatCamelCase(key)}:</Label><Value>{handleSpecial(state, key, value)}</Value>
  </tr>
);

const NoData = () => {
  const style = {
    fontFamily: Fonts.body,
    fontSize: '1.1rem',
    marginTop: '15px',
  };

  return <div style={style}>No data</div>;
};

const CustomFieldsTable = ({ state, data = {}, propFilter = p => true }) => {
  const keys = Object.keys(data).filter(propFilter);
  if (!keys.length) {
    return <NoData/>;
  }

  const tableStyle = { margin: 'auto', paddingTop: '10px' };
  return (
    <table style={tableStyle}>
      <tbody>{keys.map(key => TableItem(state, key, data[key]))}</tbody>
    </table>
  );
};

export default CustomFieldsTable;
