import React from 'react';
import { Colors, Fonts } from '../util';

const Title = ({ children }) => {
  const style = {
    color: Colors.black,
    fontFamily: Fonts.title,
    margin: '20px auto 0px',
    textAlign: 'center',
    width: '100%',
  };

  return <h2 style={style}>{children}</h2>;
};

export default Title;
