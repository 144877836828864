/**
 * Adapted from
 * https://github.com/iotaledger/mam-explorer/blob/a7f782c9ba908ce1261b8b65c4c65f9ca8b2e677/src/utils/MAM.js
 */

import { createContext, Reader, Mode } from 'mam.client.js/lib/mam';
import { createHttpClient } from '@iota/http-client';
import { trytesToAscii } from '@iota/converter';
import _get from 'lodash.get';

const IOTA_URL = 'https://iota.evrythng.io:443';
// const IOTA_URL=  'https://perma.iota.partners:443';
// const IOTA_URL = 'https://nodes.thetangle.org:443';
// const IOTA_URL = 'https://nodes.devnet.thetangle.org:443';

// Key required only for encrypted transactions, but an absence of key is not handled properly
const DUMMY_KEY = '9'.repeat(81);

/**
 * Fetch IOTA data from the MAM.
 *
 * @param {string} provider - URL of the data provider (the node).
 * @param {string} root - The MAM root address.
 * @param {string} selectedMode - The read mode. Usually 'public'.
 * @param {string} key - The private access key (required, but not used)
 * @returns {Array} Array of transactions.
 */
const mamFetch = async (provider, root, selectedMode, key) => {
  try {
    const client = createHttpClient({ provider });
    const ctx = await createContext();
    const mode = selectedMode === 'public' ? Mode.Public : Mode.Old;
    const results = [];
    let newRoot = root;
    let payload;

    do {
      const reader = new Reader(ctx, client, mode, newRoot, key);
      const message = await reader.next();
      payload = _get(message, 'value[0].message.payload');
      newRoot = _get(message, 'value[0].message.nextRoot');
      if (payload && newRoot) {
        results.push(trytesToAscii(payload));
      }
    } while(payload);
    
    return results;
  } catch (error) {
    throw new Error('MAM fetch error', error);
  }
};

/**
 * Get all IOTA transactions for a given Thng.
 *
 * @param {object} thng - The Thng to use.
 * @returns {Array} Array of transactions.
 */
const getThngIotaData = async thng => 
  mamFetch(IOTA_URL, thng.customFields.iotaRoot, 'public', DUMMY_KEY);

/**
 * Get all IOTA transactions for a given Thng's collections.
 *
 * @param {Array} collections - The collections to use.
 * @returns {Array} Array of transactions.
 */
const getCollectionsIotaData = async (collections) => {
  const promises = collections.map(p => 
    mamFetch(IOTA_URL, p.customFields.iotaRoot, 'public', DUMMY_KEY));

  // Create one big list of transactions for all collections
  return Promise.all(promises)
    .then(res => res.reduce((result, item) => result.concat(item), []));
};

module.exports = {
  getThngIotaData,
  getCollectionsIotaData,
};
