import React from 'react';

const FADE_DELAY_MS = 200;
const DURATION_S = 0.5;

class Fader extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ visible: true }), FADE_DELAY_MS);
  }

  render() {
    const { visible } = this.state;
    const style = {
      opacity: visible ? 1 : 0,
      visibility: visible ? 'visible' : 'hidden',
      transition: `${DURATION_S}s`,
    };

    return <div style={style}>{this.props.children}</div>;
  }

}

export default Fader;
