import React from 'react';
import { Colors, Fonts } from '../util';

const Container = ({ url, children }) => {
  const style = {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    border: `1px solid #0004`,
    borderRadius: '3px',
    padding: '3px',
    alignItems: 'center',
    marginLeft: '10px',
  };

  return <div style={style} onClick={() => window.open(url, '_blank')}>{children}</div>;
};

const Icon = ({ src }) => {
  const style = {
    width: '16px',
    height: 'auto',
    marginRight: '5px',
  };

  return <img style={style} src={src}></img>;
};

const Label = ({ children }) => {
  const style = {
    fontFamily: Fonts.body,
    color: Colors.darkGrey,
    fontSize: '0.8rem',
  };

  return <span style={style}>{children}</span>;
};

const IconButton = ({ url, label, src }) => (
  <Container url={url}>
    <Icon src={src}/>
    <Label>{label}</Label>
  </Container>
);

export default IconButton;
