import React from 'react';
import { Colors } from '../util';

export const NAVBAR_HEIGHT = 70;

const Bar = ({ children }) => {
  const style = {
    position: 'fixed',
    backgroundColor: Colors.white,
    display: 'flex',
    flexDirection: 'row',
    height: `${NAVBAR_HEIGHT}px`,
    textAlign: 'center', 
    alignItems: 'center',
    width: '100%',
    boxShadow: '0px 2px 3px 1px #0004',
  };

  return <div style={style}>{children}</div>;
};

const Logo = ({ src }) => {
  const style = {
    height: `${NAVBAR_HEIGHT - 15}px`,
    margin: 'auto',
    width: 'auto',
  };

  return <img style={style} src={src}/>;
};

const BackButton = ({ src, onClick }) => {
  const style = {
    position: 'fixed',
    width: '32px',
    height: '32px',
    marginLeft: '20px',
  };
  
  return <img style={style} src={src} onClick={onClick}/>;
};

export const NavBar = ({ logoSrc, showBack = false, backSrc, backOnClick }) => (
  <Bar>
    {showBack === true && <BackButton src={backSrc} onClick={backOnClick}/>}
    <Logo src={logoSrc}/>
  </Bar>
);

export const NavBarSpacer = () => {
  const style = { height: `${NAVBAR_HEIGHT + 20}px` };

  return <div style={style}/>;
};
