import React from 'react';

const PageIcon = ({ src }) => {
  const style = {
    width: '70px',
    height: 'auto',
    margin: '30px auto 0px',
  };

  return <img style={style} src={src}/>
};

export default PageIcon;
