import React from 'react';
import {
  Colors, Fonts, ActionTypeIcons, SKIP_IOTA, formatCamelCase, getLocationString, friendlyName,
  getTimestamp,
} from '../util';
import IconButton from './IconButton';

const IOTA_EXPLORER_URL = 'https://thetangle.org/mam';

const ListContainer = ({ children }) => {
  const style = { width: '100%', margin: 'auto' };

  return <div style={style}>{children}</div>;
};

const Container = ({ children }) => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    padding: '5px 5px 0px',
    maxWidth: '330px',
    backgroundColor: 'white',
  };

  return <div style={style}>{children}</div>;
};

const EventIcon = ({ src }) => {
  const style = {
    height: 'auto',
    width: '28px',
    marginRight: '5px',
  };

  return <img style={style} src={src}/>;
};

const Type = ({ children }) => {
  const style = {
    color: 'black',
    fontFamily: Fonts.body,
    fontSize: '1.0rem',
    padding: '0px',
    textAlign: 'left',
    fontWeight: 'bold',
    flex: '3',
  };

  return <span style={style}>{children}</span>;
};

const Bar = ({ children }) => {
  const style = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  };

  return <div style={style}>{children}</div>;
};

const DateTime = ({ children }) => {
  const style = {
    color: Colors.lightGrey,
    fontFamily: Fonts.body,
    fontSize: '0.9rem',
    flex: '1',
  };

  return <div style={style}>{children}</div>;
};

const Descriptor = ({ children }) => {
  const style = {
    color: Colors.darkGrey,
    fontFamily: Fonts.body,
    fontSize: '0.9rem',
    marginLeft: '19px',
    marginBottom: '5px',
    textAlign: 'left',
  };

  return <div style={style}>{children}</div>;
};

const ValidityBanner = ({ isVerified }) => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: isVerified ? Colors.lightGreen : Colors.notAuthenticText,
    color: 'white',
    width: '123px',
    height: '24px',
    paddingLeft: '5px',
    fontFamily: Fonts.body,
    fontSize: '0.8rem',
    textAlign: 'left',
    marginLeft: '17px',
  };

  return (
    <div style={style}>{isVerified ? 'Blockchain Validated' : 'Not Validated'}</div>
  );
};

const ContentContainer = ({ children, showBorder }) => {
  const style = {
    borderLeft: showBorder ? `2px solid ${Colors.lightGrey}` : 'none',
    marginLeft: '12px',
    marginTop: '4px',
    paddingBottom: '20px',
  };

  return <div style={style}>{children}</div>;
};

const CompositionDetails = ({ details }) => {
  const style = { marginTop: '5px' };

  return (
    <div style={style}>
      <Descriptor>Composition: {details}</Descriptor>
    </div>
  );
};

const ActionHistoryItem = (historyItem, index, items) => {
  const {
    id, type, timestamp, firstName, context, originTrailUrl, iotaRoot, eventData,
  } = historyItem;
  const dateStr = new Date(getTimestamp(historyItem)).toLocaleString()
  const dateTimeStr = dateStr.substring(0, dateStr.indexOf(','));
  const locationStr = getLocationString(context);
  const descriptorStr = `${type !== '_Commissioned' ? locationStr : ''} ${firstName ? `- by ${firstName}` : ''}`;
  const iotaUrl = `${IOTA_EXPLORER_URL}/${iotaRoot}`;

  let isVerified = historyItem.isVerified || SKIP_IOTA;

  return (
    <Container key={id}>
      <Bar>
        <EventIcon src={ActionTypeIcons[type] || '../../assets/calendar-text.png'}/>
        <Type>{formatCamelCase(friendlyName(type))}</Type>
        <DateTime>{dateTimeStr}</DateTime>
      </Bar>

      <ContentContainer showBorder={index !== (items.length - 1)}>
        <Descriptor>{descriptorStr}</Descriptor>
        {eventData.compositionDetails != null && (
          <CompositionDetails details={eventData.compositionDetails}/>
        )}
        <Bar>
          {isVerified === true && <ValidityBanner isVerified={isVerified}/>}
          {isVerified === true && originTrailUrl !== undefined && (
            <IconButton url={originTrailUrl} label="Data" src="../../assets/origintrail.png"/>
          )}
          {isVerified === true && iotaRoot !== undefined && (
            <IconButton url={iotaUrl} label="Proof" src="../../assets/iota.png"/>
          )}
        </Bar>
      </ContentContainer>
    </Container>
  );
};

const ActionHistoryStack = ({ state }) => (
  <ListContainer>{state.eventHistory.map(ActionHistoryItem)}</ListContainer>
);

export default ActionHistoryStack;
