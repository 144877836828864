import React from 'react';
import { buttonStyle } from '../util';

const Button = ({ children, restyle, onClick }) => {
  const styles = Object.assign({}, buttonStyle, restyle);
  
  return <div style={styles} onClick={onClick}>{children}</div>;
};

export default Button;
