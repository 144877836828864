import React from 'react';
import jsQR from 'jsqr';
import { Colors } from '../util';

const SCANTHNG_CONTAINER_ID = 'scanthng-container';

class ScanThngPage extends React.Component {

  /**
   * When the component is mounted.
   */
  componentDidMount() {
    const { onThngScanned, onShortIdNoThng, onScanFail } = this.props.appCallbacks;

    // Use npm version of jsQR
    window.jsQR = jsQR;

    // Begin scanning
    EVT.use(EVT.Scan);
    this.props.state.appScope.scanStream({
      filter: { method: '2d', type: 'qr_code' },
      containerId: SCANTHNG_CONTAINER_ID,
    })
      .then((res) => {
        // Nothing at all
        if (!res.length) {
          return Promise.reject('No code identified in image');
        }

        // Text decoded, but no Thng found
        if(!res[0].results.length) {
          return onShortIdNoThng(res[0].meta.value);
        }

        // Something was identified, but it was not a Thng (product?)
        if (!res[0].results[0].thng) {
          return Promise.reject('No Thng found!');
        }

        return onThngScanned(res[0].results[0].thng.id);
      })
      .catch((err) => {
        alert(err);

        onScanFail();
      });
  }

  /**
   * Component render function.
   */
  render() {
    const style = {
      width: '100%',
      height: '100%',
      backgroundColor: Colors.black,
    };

    return <div id={SCANTHNG_CONTAINER_ID} style={style}></div>;
  }

}

export default ScanThngPage;
