import React from 'react';
import { Colors, Fonts } from '../util';

const Container = ({ children }) => {
  const style = {
    width: '100%',
    alignItems: 'center',
  };

  return <div style={style}>{children}</div>;
};

const Icon = ({ src }) => {
  const style = {
    width: '18px',
    height: '18px',
    marginRight: '5px',
  };

  return <img style={style} src={src}/>;
};

const Label = ({ children }) => {
  const style = {
    fontFamily: Fonts.body,
    fontSize: '1.1rem',
    color: Colors.darkGrey,
    textAlign: 'center',
    paddingBottom: '3px',
  };

  return <span style={style}>{children}</span>;
};

const BlockchainBanner = () => (
  <Container>
    <Icon src="../../assets/link-variant.png"/>
    <Label>Powered by Blockchain</Label>
  </Container>
);

export default BlockchainBanner;
