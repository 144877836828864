import React from 'react';
import { Colors, Fonts } from '../util';

const Subtitle = ({ children }) => {
  const style = {
    color: Colors.black,
    fontFamily: Fonts.title,
    margin: '20px auto 0px',
    textAlign: 'center',
    width: '100%',
  };

  return <h3 style={style}>{children}</h3>;
};

export default Subtitle;
